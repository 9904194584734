:root {
    --primary-blue: #001E78;
    --button-blue: #41c4f1;
    --white: #fff;
    --box-shadow: #eee;
    --black: #000;
    --BlushWhite:#fdf4f4;
    --gray: #8c9562;
    --white: white;
    --blue: #001E78;
    --lightGray: #ccd3b4;
    --oliveGreen: #607438;
    --Sandstone:#c3bb80;
    --AutumnOlive:#75724C;
}