.box-container {   
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: white; 
    padding: 10px 20px;
    margin-right: auto;
    justify-content: center; 
    margin-top: -5px; 
    box-shadow: 0 1px 2px -1px #0c0c0c1a, 0 1px 3px #0c0c0c1a;
    border-radius: 10px; 
    flex-direction: column;
}
.box-container1 {   
    box-sizing: border-box;
    display: flex;
    /* align-items: center; */
    background-color: white; 
    padding: 10px 20px;
    margin-right: auto;
    /* justify-content: center;  */
    margin-top: -5px; 
    box-shadow: 0 1px 2px -1px #0c0c0c1a, 0 1px 3px #0c0c0c1a;
    border-radius: 10px; 
    flex-direction: column;
}

.top-bar {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    gap: 10px;
    width: 102%;
    max-width: 110%;
    padding: 10px 20px;
    background-color: white; 
    margin-bottom: 10px;
}
.search-bar {
    display: flex;
    align-items: center;
    width: 102%;
    max-width: 110%;
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: white; 
}
.question-box {
    width: 100%;
    max-width: 100%;
    padding: 10px 20px;
    background-color: white; 
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}
.version-tag {
    margin-left: auto; 
    display: flex;
    align-items: center;
    gap: 0px; 
    color: #747247;
}
.question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    width: 100%;
}
.images-tag {
    background-color: #e0ddd3;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #747247;
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.top-bar .MuiOutlinedInput-input{
    padding: 10px 15px !important;
    padding-right: 30px !important;
}
.option-text {
    color: #9E9E9E; 
    font-size: 16px;
    padding: 8px 0; 
    display: block
}
@media (max-width: 1024px) {
    .box-container {
        padding: 10px;
    }

    .question-box,
    .top-bar,
    .search-bar {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .box-container {
        padding: 5px;
    }

    .question-box,
    .top-bar,
    .search-bar {
        width: 100% !important;
    }

    .top-bar {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 480px) {
    .box-container {
        padding: 5px;
    }

    .question-box,
    .top-bar,
    .search-bar {
        width: 100% !important;
    }
}
