.extra .fs-18 {
  font-size: 18px !important;
}

.extra .bg-grey {
  background-color: #747247 !important;
  text-transform: none;
}

.extra .transform {
  text-transform: none !important;
}

.extra .bg-light-primary {
  background-color: #7472471A;
  text-transform: none;
}
.extra .w-33{
 /* min-width: 33%; */
 flex-basis: 33%;
 margin-bottom: 30px;
}
.extra .fw-bold{
  font-weight: bold;
}

.extra .MuiToggleButton-root,
.lastButton {
  padding: 0px 5px;
  margin: 0px 10px;
  border: 1px solid #a3a3a3;
  border-left: 1px solid #a3a3a3 !important;
  border-radius: 5px !important;
}

.extra .MuiToggleButton-root.Mui-selected {
  background-color: #7472471A !important;
  color: #747247 !important;
  border-color: #747247 !important;
}

.extra .text-alternate {
  color: #212121 !important;
}
.extra .border-x{
  border-left:1px solid gray;
  border-right:1px solid grey;
}
.extra ol {
  list-style-type: decimal !important;
}

.extra .MuiInputLabel-root {
  font-size: 14px !important;
  top: -40px !important;
  left: -10px !important;
}

.extra .MuiOutlinedInput-input {
  padding: 10px !important;
}

.extra .MuiCheckbox-root.Mui-checked {
  color: white !important;
}

.extra .w-49 {
  width: 49% !important;
}

.extra .MuiIconButton-root:hover {
  background-color: transparent !important;
}

.extra .whitespace {
  white-space: nowrap;
}

.extra .MuiIconButton-root:active {
  background-color: transparent !important;
  box-shadow: none !important;
}

.extra .MuiIconButton-root:focus {
  outline: none !important;
  box-shadow: none !important;
}

.extra fieldset {
  border: 1px solid #E0E0E0 !important;
}
.extra .font .MuiInput-input{
  font-size: x-large !important;
}
.extra .font .MuiSvgIcon-root{
  height: 30px !important;
  width: 30px !important;
}

.extra .Mui-focused fieldset {
  border-color: #747247 !important;
}

.extra fieldset {
  border: 1px solid #E0E0E0 !important;
}

.extra .MuiInputLabel-root.Mui-focused,
.text-main {
  color: #747247 !important;
}

.extra .MuiOutlinedInput-input {
  box-shadow: none !important;
}